.ic-menu:before {
  content: "menu";
}

.ic-home:before {
  content: "home";
}

.ic-add:before {
  content: "add";
}
.ic-export:before {
  content: "import_export";
}
.ic-delete:before {
  content: "delete";
}
.ic-phone:before {
  content: "phone";
}

.ic-folder:before {
  content: "folder";
}

.ic-people:before {
  content: "people";
}

.ic-build:before {
  content: "build";
}

.ic-settings:before {
  content: "settings";
}

.ic-help:before {
  content: "help_outline";
}

.ic-user:before {
  content: "account_circle";
}

.ic-edit:before {
  content: "edit";
}

.ic-upload:before {
  content: "file_upload";
}

.ic-download:before {
  content: "download";
}

.ic-logout:before {
  content: "logout";
}

.ic-import:before {
  content: "upload_file";
}

.ic-column:before {
  content: "view_column";
}
.ic-event:before {
  content: "event";
}

.ic-email:before {
  content: "email";
}

.ic-analytics:before {
  content: "leaderboard";
}

.ic-delete_sweep:before {
  content: "delete_sweep";
}

.ic-bar_chart:before {
  content: "bar_chart";
}
.ic-query:before {
  content: "query_builder";
}

.ic-execute:before {
  content: "play_circle";
}

.ic-clear:before {
  content: "clear";
}

.ic-store:before {
  content: "store";
}

.material-icons-outlined {
  font-size: 18px !important;
}

* {
  margin: 0;
  padding: 0;
}

.App {
  height: 100%;
  width: 100%;
}

.navBar {
  position: sticky;
  top: 0;
  z-index: 999;
  max-width: 100%;
  padding: 10px;

  padding: 10px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  height: 70px;
}
#toolbar-logo {
  object-fit: contain;
  height: 20px;
  margin-left: 10px;
}

.dx-drawer-left .dx-drawer-panel-content {
  width: 200px;
}
.home-container {
  /* border: solid red; */
  width: 100%;
  height: calc(100vh - 100px);
}

.dx-filemanager-dirs-panel {
  min-width: 100% !important;
}

.initPage {
  height: 100vh;
}
