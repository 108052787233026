.settings-container {
  display: flex;
  flex-direction: column;
}

.settings-container > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 5em;
  border-bottom: solid 1px lightgrey;
}
