.login {
  /* position: fixed;
  top: 50%;
  left: 50%;
  height: 400px;
  width: 500px;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgb(242, 242, 242);
}

.login > div {
  display: flex;
  flex-direction: column;
  height: 50%;
  width: 20%;

  justify-content: space-between;
  align-items: center;
  padding: 50px !important;
}

.login > div > img {
  object-fit: contain;
  width: 100% !important;
}

@media only screen and (max-width: 550px) {
  .login {
    width: 100%;
    height: 100vh;
  }
}

@media only screen and (max-width: 550px) {
  .login > div {
    width: 80%;
    height: 100vh;
  }
}
