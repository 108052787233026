.panel-list {
  margin-top: 70px;

  position: fixed;
  padding: 0px;
  width: 200px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 2px;
}

.listItem {
  display: flex;
  align-items: center;
}

.listItem > p {
  margin-left: 10px;
}

.panel-list .dx-list-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

#view {
  padding: 20px;
  max-height: calc(100% - 70px) !important;
  margin-top: 70px;
  width: 100% !important;
}
